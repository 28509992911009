import Router from './Router';
import './App.css';
import './css/style.css';


function App() {
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
